<template>
    <v-navigation-drawer v-model="drawerShow" mobile-breakpoint="960" height="100%" app>
        <v-list class="py-0 company-logo" color="primary" style="border-radius:0;height:64px;">
            <v-list-item>
                <v-list-item-content>
                    <v-img src="/img/logo.svg" max-height="40px" contain></v-img>
                </v-list-item-content>
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn small icon @click.stop="navToggle" class="mx-0">
                        <v-icon class="white--text">mdi-close</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-divider class="my-0"></v-divider>
        <scrollbar ref="scrollbar" :swicher="!$vuetify.breakpoint.smAndDown"
                   :settings="settingsScrollbar" class="inner-scrollbar">
            <v-list class="py-0" dense v-for="(group, index) in items" :key="index">
                <template v-for="item in group">
                    <v-subheader v-if="item.header" v-show="item.visible">{{ $t(item.title) }}</v-subheader>
                    <v-list-group v-else-if="!!item.items" v-show="item.visible" :prepend-icon="item.icon" no-action
                                  :key="item.title" v-model="item.active">
                        <template v-slot:activator>
                            <v-list-item-content class="nav-bar-content">
                                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item v-for="subItem in item.items" :key="subItem.title" v-show="subItem.visible"
                                     @click.native="subItem.action ? subItem.action() : false" :to="subItem.to" ripple
                                     :exact="subItem.exact !== undefined ? subItem.exact : true">
                            <v-list-item-icon>
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pl-2 nav-bar-content">
                                <v-list-item-title>{{ $t(subItem.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-else-if="!item.header" v-show="item.visible"
                                 @click.native="item.action ? item.action() : false"
                                 href="javascript:void(0)" :to="item.to" ripple
                                 :exact="item.exact !== undefined ? item.exact : true" :key="item.title">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="nav-bar-content">
                            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </scrollbar>
    </v-navigation-drawer>
</template>
<script>

import Scrollbar from './Scrollbar'

export default {
    name: "AppSidebar",
    components: {
        Scrollbar,
    },
    inject: ['forceRerender'],
    props: {
        drawer: Boolean
    },
    data() {
        return {
            isMini: null,
            items: [],
            settingsScrollbar: {
                suppressScrollX: true,
            },
        }
    },
    computed: {
        drawerShow: {
            get: function () {
                return this.drawer
            },
            set: function (state) {
                if (state !== this.drawer) {
                    this.navToggle()
                }
            }
        },
        routePath() {
            return this.$route.path
        },
    },
    watch: {
        routePath(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.navigation()
            }
        },
    },
    mounted() {
        this.navigation()
    },
    methods: {
        navToggle() {
            this.$emit("nav-toggle")
        },
        navigation() {
            this.items = [
                [
                    {
                        title: 'menu_general',
                        header: true,
                        visible: this.$auth.check(),
                    },
                    {
                        title: 'menu_dashboard',
                        icon: "mdi-view-dashboard mdi-18px",
                        to: {name: "dashboard"},
                        action: this.forceRerender,
                        visible: this.$auth.check(),
                        exact: false
                    },
                    {
                        title: 'menu_billing',
                        header: true,
                        visible: this.can(['administrator', 'manager', 'accountant']),
                    },
                    {
                        title: 'menu_orders',
                        icon: "mdi-shopping mdi-18px",
                        to: {name: "order"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager', 'accountant']),
                        exact: false
                    },
                    {
                        title: 'menu_pushes',
                        icon: "mdi-notification-clear-all mdi-18px",
                        to: {name: "push"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_individual_notifications',
                        icon: "mdi-notification-clear-all mdi-18px",
                        to: {name: "individual_notification"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_administration',
                        header: true,
                        visible: this.can(['administrator']),
                    },
                    {
                        title: 'menu_users',
                        icon: 'mdi-account-group mdi-18px',
                        visible: this.can(['administrator']),
                        exact: false,
                        active: ['/administrator', '/partner', '/client'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_administrators',
                                icon: "mdi-account-tie mdi-18px",
                                to: {name: 'administrator'},
                                action: this.forceRerender,
                                visible: this.can(['administrator']),
                                exact: false
                            },
                            {
                                title: 'menu_partners',
                                icon: "mdi-account-supervisor mdi-18px",
                                to: {name: 'partner'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_clients',
                                icon: "mdi-account mdi-18px",
                                to: {name: 'client'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                        ]
                    },
                    {
                        title: 'menu_companies',
                        icon: 'mdi-home-city mdi-18px',
                        to: {name: "company"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_news',
                        icon: "mdi-newspaper-variant-outline mdi-18px",
                        to: {name: 'news'},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_certificates',
                        icon: "mdi-ticket-percent-outline mdi-18px",
                        to: {name: 'certificate'},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_banners',
                        icon: "mdi-picture-in-picture-top-right mdi-18px",
                        to: {name: 'banner'},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_art_categories',
                        icon: "mdi-earth-box mdi-18px",
                        to: {name: "art_category"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_shops',
                        icon: "mdi-home-flood mdi-18px",
                        to: {name: "shop"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'moderator']),
                        exact: false
                    },
                    {
                        title: 'Arizar',
                        icon: 'mdi-car-cog mdi-18px',
                        visible: this.can(['administrator', 'manager']),
                        exact: false,
                        active: ['/car_energy', '/product', '/category_shop', '/category_product'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'Arizar',
                                icon: "mdi-car-cog mdi-18px",
                                to: {name: "car_energy"},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'moderator']),
                                exact: false
                            },
                            {
                                title: 'menu_products',
                                icon: "mdi-shopping mdi-18px",
                                to: {name: "product"},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'moderator']),
                                exact: false
                            },
                            {
                                title: 'menu_category_shops',
                                icon: "mdi-folder-text-outline mdi-18px",
                                to: {name: "category_shop"},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_category_products',
                                icon: "mdi-folder-outline theme--light mdi-18px",
                                to: {name: "category_product"},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                        ]
                    },

                    {
                        title: 'menu_food',
                        icon: "mdi-food mdi-18px",
                        to: {name: 'food'},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },

                    {
                        title: 'menu_coffee_houses',
                        icon: "mdi-coffee-maker mdi-18px",
                        to: {name: 'coffee_house'},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },


                    {
                        title: 'menu_restaurants',
                        icon: 'mdi-food-variant mdi-18px',
                        visible: this.can(['administrator', 'manager']),
                        exact: false,
                        active: ['/restaurant', '/restaurant_table', '/restaurant_reservation'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_restaurant',
                                icon: "mdi-food-variant mdi-18px",
                                to: {name: 'restaurant'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_restaurant_table',
                                icon: "mdi-table-chair mdi-18px",
                                to: {name: 'restaurant_table'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_restaurant_reservation',
                                icon: "mdi-content-paste mdi-18px",
                                to: {name: 'restaurant_reservation'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                        ]
                    },
                    {
                        title: 'menu_fitness',
                        icon: 'mdi-dumbbell mdi-18px',
                        visible: this.can(['administrator', 'manager']),
                        exact: false,
                        active: ['/fitness', '/fitness_main', '/trainer', '/group_lesson', '/type_service', '/fitness_calendar', '/price_subscription'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_fitness',
                                icon: "mdi-dumbbell mdi-18px",
                                to: {name: 'fitness'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_fitness_main',
                                icon: "mdi-view-list mdi-18px",
                                to: {name: 'fitness_main'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_trainers',
                                icon: "mdi-account-box-outline mdi-18px",
                                to: {name: 'trainer'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_group_lessons',
                                icon: "mdi-account-multiple-outline mdi-18px",
                                to: {name: 'group_lesson'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_fitness_calendars',
                                icon: "mdi-calendar-month-outline mdi-18px",
                                to: {name: 'fitness_calendar'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_price_subscriptions',
                                icon: "mdi-cash-check mdi-18px",
                                to: {name: 'price_subscription'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_type_services',
                                icon: "mdi-check-circle-outline mdi-18px",
                                to: {name: 'type_service'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                        ]
                    },
                    {
                        title: 'menu_hotel',
                        icon: 'mdi-home-variant-outline mdi-18px',
                        visible: this.can(['administrator', 'manager']),
                        exact: false,
                        active: ['/hotel', '/room', '/room_type', '/reserve_room'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_hotel',
                                icon: "mdi-home-variant-outline mdi-18px",
                                to: {name: 'hotel'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_room',
                                icon: "mdi-google-classroom mdi-18px",
                                to: {name: 'room'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_reservation_room',
                                icon: "mdi-content-paste mdi-18px",
                                to: {name: 'reserve_room'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_room_type',
                                icon: "mdi-home-group-plus mdi-18px",
                                to: {name: 'room_type'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                        ]
                    },
                    {
                        title: 'menu_cinema',
                        icon: 'mdi-camcorder mdi-18px',
                        visible: this.can(['administrator', 'manager']),
                        exact: false,
                        active: ['/cinema'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_cinema',
                                icon: "mdi-camcorder mdi-18px",
                                to: {name: 'cinema'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                        ]
                    },
                    {
                        title: 'menu_mall',
                        icon: 'mdi-home-variant mdi-18px',
                        visible: this.can(['administrator', 'manager']),
                        exact: false,
                        active: ['/mall'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_mall',
                                icon: "mdi-home-variant mdi-18px",
                                to: {name: 'mall'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                        ]
                    },
                    {
                        title: 'menu_park',
                        icon: 'mdi-candycane mdi-18px',
                        visible: this.can(['administrator', 'manager']),
                        exact: false,
                        active: ['/park', '/attraction'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_park',
                                icon: "mdi-candycane mdi-18px",
                                to: {name: 'park'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_attraction',
                                icon: "mdi-nintendo-switch mdi-18px",
                                to: {name: 'attraction'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_park_restaurant',
                                icon: "mdi-food-variant mdi-18px",
                                to: {name: 'park_restaurant'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_park_restaurant_menu',
                                icon: "mdi-file-pdf-box mdi-18px",
                                to: {name: 'park_restaurant_menu'},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                        ]
                    },
                    {
                        title: 'menu_faq',
                        icon: 'mdi-help-box mdi-18px',
                        visible: this.can(['administrator', 'manager']),
                        exact: false,
                        active: ['/faq'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_faq_profile',
                                icon: "mdi-account-tie mdi-18px",
                                to: {name: 'faq', params: {type: 1}},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                            {
                                title: 'menu_faq_parking',
                                icon: "mdi-car-brake-parking mdi-18px",
                                to: {name: 'faq', params: {type: 2}},
                                action: this.forceRerender,
                                visible: this.can(['administrator', 'manager']),
                                exact: false
                            },
                        ]
                    },
                    {
                        title: 'menu_loyalty',
                        icon: "mdi-brightness-percent mdi-18px",
                        to: {name: "loyalty"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_pages'),
                        icon: "mdi-page-layout-header-footer mdi-18px",
                        to: {name: "page"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'moderator']),
                        exact: false
                    },
                    {
                        title: 'menu_handbooks',
                        header: true,
                        visible: this.can(['administrator', 'manager']),
                    },

                    {
                        title: 'menu_chain',
                        icon: "mdi-home-switch-outline mdi-18px",
                        to: {name: "chain"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_brands',
                        icon: "mdi-alpha-b-circle-outline mdi-18px",
                        to: {name: "brand"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_type_certificates',
                        icon: "mdi-ticket-percent mdi-18px",
                        to: {name: "type_certificate"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_time_subscriptions',
                        icon: "mdi-timetable mdi-18px",
                        to: {name: "time_subscription"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_subscription_names',
                        icon: "mdi-clipboard-account-outline mdi-18px",
                        to: {name: "subscription_name"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_type_subscriptions',
                        icon: "mdi-id-card mdi-18px",
                        to: {name: "type_subscription"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_subscription_properties',
                        icon: "mdi-account-child mdi-18px",
                        to: {name: "subscription_property"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_type_tables',
                        icon: "mdi-table-furniture mdi-18px",
                        to: {name: "type_tables"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_type_companies',
                        icon: "mdi-home-variant-outline mdi-18px",
                        to: {name: "type_company"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_day_weeks',
                        icon: "mdi-calendar mdi-18px",
                        to: {name: "day_week"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_shopping_center_service',
                        icon: "mdi-home-outline mdi-18px",
                        to: {name: "shopping_center_service"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_sexes',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "sex"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    }

                ]
            ];
        }
    }
};

</script>
