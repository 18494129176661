<template>
    <v-container fluid>
        <v-form @submit.prevent="savePush">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'push'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="name" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="name" type="text" :disabled="loading"
                                                  :error="!valid" :error-messages="errors"
                                                  prepend-icon="mdi-notification-clear-all"
                                                  :label="$t('push_title')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="body" rules="required|min:2|max:120"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="body" type="text" :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-message-text-outline"
                                                  :label="$t('body')" color="primary" clearable required>{{
                                            body }}
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="sex"  rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="sex" :items="sexItems" :disabled="loading" item-text="name"
                                              :error-messages="errors" item-value="id"
                                              prepend-icon="mdi-gender-male-female"
                                              :label="$t('sex')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="ageFrom"
                                                    rules="min_value:14|max_value:100"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="ageFrom" :items="ageFromItems" :disabled="loading"
                                              :error-messages="errors" prepend-icon="mdi-face-man"
                                              :label="$t('age_from')" color="primary"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="ageTo"
                                                    rules="min_value:14|max_value:100"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="ageTo" :items="ageToItems" :disabled="loading"
                                              :error-messages="errors" prepend-icon="mdi-face-man"
                                              :label="$t('age_from')" color="primary"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="4">
                                <datetime-picker v-model="dateTime"
                                                 validator-rules="required|date_time_format_hm" :disabled="loading"
                                                 format="24hr" prepend-icon="mdi-calendar"
                                                 :label="$t('start_date_of_sending')" :locale="lang"
                                                 :min-date="$moment().format('YYYY-MM-DD')"
                                                 :timezone="$auth.user().timezone" timeFormat="HH:mm" color="primary"
                                                 readonly clearable></datetime-picker>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="8">
                                <ValidationProvider ref="type" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="type" :items="TypeItems" :error="!valid"
                                                    :error-messages="errors" :disabled="loading"
                                                    prepend-icon="mdi-typewriter" item-text="name" item-value="id"
                                                    :label="$t('type_link')" color="primary"
                                                    @change="resetTypeParam"></v-autocomplete>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" v-if="type ==='shop'">
                                <ValidationProvider ref="shop" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="shop" :items="shopItems"
                                                    :error-messages="errors" :error="!valid"
                                                    :search-input.sync="shopSearching"
                                                    item-text="name" item-value="id"
                                                    prepend-icon="mdi-home-flood"
                                                    append-icon="mdi-location-enter"
                                                    :loading="loadingShops" :disabled="loading"
                                                    :no-data-text="shopSearching ? $t('nothing_found_by',{'search': shopSearching}) : $t('nothing_found_text')"
                                                    :label="$t('shop')"
                                                    @click:clear="shopItems=[]"
                                                    @click="clearShops"
                                                    @click:append="redirectToShop(shop)"
                                                    color="primary" clearable autocomplete="off">
                                        <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.address_legal"></span>
                                            </span>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.address_legal"></span>
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="12" v-if="type === 'coffee_house'">
                                <ValidationProvider ref="coffee_house" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="coffee_house" :disabled="loadingCoffeeHouses"
                                              :items="coffeeHouseItems" :error-messages="errors"
                                              prepend-icon="mdi-coffee-maker" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('coffee_house')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="12" v-if="type === 'restaurant'">
                                <ValidationProvider ref="restaurant" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="restaurant" :disabled="loadingRestaurants"
                                              :items="restaurantItems" :error-messages="errors"
                                              prepend-icon="mdi-food-variant" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('restaurant')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="12" v-if="type === 'fitness'">
                                <ValidationProvider ref="fitness" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="fitness" :disabled="loadingFitness"
                                              :items="fitnessItems" :error-messages="errors"
                                              prepend-icon="mdi-dumbbell" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('fitness')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="12" v-if="type === 'hotel'">
                                <ValidationProvider ref="hotel" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="hotel" :disabled="loadingFitness"
                                              :items="hotelItems" :error-messages="errors"
                                              prepend-icon="mdi-dumbbell" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('hotel')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="12" v-if="type === 'cinema'">
                                <ValidationProvider ref="cinema" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="cinema" :disabled="loadingCinema"
                                              :items="cinemaItems" :error-messages="errors"
                                              prepend-icon="mdi-camcorder" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('cinema')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" v-if="type ==='certificate'">
                                <ValidationProvider ref="certificate" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="certificate" :items="certificateItems"
                                                    :error-messages="errors" :error="!valid"
                                                    :search-input.sync="certificateSearching"
                                                    item-text="title" item-value="id"
                                                    prepend-icon="mdi-home-flood"
                                                    append-icon="mdi-location-enter"
                                                    :loading="loadingCertificates" :disabled="loading"
                                                    :no-data-text="certificateSearching ? $t('nothing_found_by',{'search': certificateSearching}) : $t('nothing_found_text')"
                                                    :label="$t('certificate')"
                                                    @click:clear="certificateItems=[]"
                                                    @click="clearCertificate"
                                                    @click:append="redirectToCertificate(certificate)"
                                                    color="primary" clearable autocomplete="off">
                                        <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.type_company"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.title"></span>
                                            </span>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.type_company"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.title"></span>
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" v-if="type ==='news'">
                                <ValidationProvider ref="news" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="news" :items="newsItems"
                                                    :error-messages="errors" :error="!valid"
                                                    :search-input.sync="newsSearching"
                                                    item-text="title" item-value="id"
                                                    prepend-icon="mdi-home-flood"
                                                    append-icon="mdi-location-enter"
                                                    :loading="loadingNews" :disabled="loading"
                                                    :no-data-text="newsSearching ? $t('nothing_found_by',{'search': newsSearching}) : $t('nothing_found_text')"
                                                    :label="$t('news')"
                                                    @click:clear="newsItems=[]"
                                                    @click="clearNews"
                                                    @click:append="redirectToNews(news)"
                                                    color="primary" clearable autocomplete="off">
                                        <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.title"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.author"></span>
                                            </span>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.title"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.author"></span>
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="12" v-if="type === 'my_link'">
                                <ValidationProvider ref="link"
                                                    rules="required|link" v-slot="{ errors, valid }">
                                    <v-text-field v-model="link" type="text" :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-web"
                                                  :label="$t('my_link')" color="primary" clearable
                                                  :required="type === 'my_link'">{{ link }}
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="12" v-if="type === 'park'">
                                <ValidationProvider ref="park" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="park" :disabled="loadingPark"
                                              :items="parkItems" :error-messages="errors"
                                              prepend-icon="mdi-camcorder" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('park')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>

                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import DatetimePicker from '../components/DatetimePicker.vue'
import debounce from "lodash/debounce";

export default {
    name: 'PushForm',
    components: {
        ValidationProvider,
        ValidationObserver,
        DatetimePicker,
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            name: null,
            body: null,
            sex: null,
            sexItems: [],
            ageFrom: null,
            ageTo: null,
            dateTime: null,
            type: null,
            TypeItems: [
                {
                    'id': 'shop',
                    'name': this.$t('shop')
                },
                {
                    'id': 'coffee_house',
                    'name': this.$t('coffee_house')
                },
                {
                    'id': 'restaurant',
                    'name': this.$t('restaurant')
                },
                {
                    'id': 'fitness',
                    'name': this.$t('fitness')
                },
                {
                    'id': 'cinema',
                    'name': this.$t('cinema')
                },
                {
                    'id': 'park',
                    'name': this.$t('park')
                },
                {
                    'id': 'certificate',
                    'name': this.$t('certificate')
                },
                {
                    'id': 'hotel',
                    'name': this.$t('hotel')
                },
                {
                    'id': 'news',
                    'name': this.$t('news')
                },
                {
                    'id': 'my_link',
                    'name': this.$t('my_link')
                },
                {
                    'id': 'no_link',
                    'name': this.$t('no_link')
                }

            ],
            shop: null,
            shopItems: [],
            shopSearching: null,
            loadingShops: false,
            certificate: null,
            certificateItems: [],
            certificateSearching: null,
            loadingCertificates: false,
            coffee_house: null,
            coffeeHouseItems: [],
            loadingCoffeeHouses: false,
            restaurant: null,
            restaurantItems: [],
            loadingRestaurants: false,
            fitness: null,
            fitnessItems: [],
            loadingFitness: false,
            hotel: null,
            hotelItems: [],
            loadingHotel: false,
            cinema: null,
            cinemaItems: [],
            loadingCinema: false,
            park: null,
            parkItems: [],
            loadingPark: false,
            news: null,
            newsItems: [],
            newsSearching: null,
            loadingNews: false,
            link: null,
            exist_translations: {},
            all_translations: true,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
        ageFromItems() {
            let arr = []
            for (let i = 14; i <= (this.ageTo ? this.ageTo : 100); i++) {
                arr.push(i)
            }
            return arr;
        },
        ageToItems() {
            let arr = []
            for (let i = (this.ageFrom ? this.ageFrom : 14); i <= 100; i++) {
                arr.push(i)
            }
            return arr;
        },
    },
    watch: {
        certificateSearching: debounce(function (val) {
            if (val && !this.certificate) {
                this.getCertificates(val)
            }
        }, 500),
        shopSearching: debounce(function (val) {
            if (val && !this.shop) {
                this.getShops(val)
            }
        }, 500),
        newsSearching: debounce(function (val) {
            if (val && !this.news) {
                this.getNews(val)
            }
        }, 500),
        type() {
            switch (this.type){
                case 'coffee_house' : this.getCoffeeHouses(); break;
                case 'restaurant' : this.getRestaurants(); break;
                case 'fitness' : this.getFitness(); break;
                case 'hotel' : this.getHotels(); break;
                case 'cinema' : this.getCinema(); break;
                case 'park' : this.getPark(); break;
            }
        },

    },
    mounted() {
        this.language = this.languages[this.tab]
        this.getSexes();
        this.checkCreate()
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "push.create") {
                this.heading = this.$t('push_creation')
            } else {
                this.heading = this.$t('push_editing')
                if (this.$route.params.id) {
                    this.getPush()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        resetTypeParam() {

            this.shop = null
            this.certificate = null
            this.coffee_house = null
            this.restaurant = null
            this.fitness = null
            this.hotel = null
            this.cinema = null
            this.park = null
            this.news = null
            this.link = null
        },
        async getSexes() {
            this.loading = true
            let params = {}


            await this.$http
                .get("admin/sex", {
                    params: params,
                })
                .then(res => {
                    this.sexItems = res.body.data
                })
                .catch(err => {
                    this.sexItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_sexes'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        clearShops() {
            if (!this.shop) {
                this.shopItems = []
            }
        },
        redirectToShop(val) {
            if (val) {
                this.$router.push({
                    name: 'shop.edit',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getShops(str) {
            if (str) {
                this.loadingShops = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.shop = str
                }
                await this.$http
                    .get("admin/shop", {
                        params: params,
                    })
                    .then(res => {
                        this.shopItems = res.body.data
                    })
                    .catch(err => {
                        this.shopItems = []
                    })
                    .finally(end => {
                        this.loadingShops = false
                    })
            }
        },
        async getCoffeeHouses() {

            this.loadingCoffeeHouses = true
            let params = {}
            params.filter = 'all'
            await this.$http
                .get("admin/coffee_house", {
                    params: params,
                })
                .then(res => {
                    this.coffeeHouseItems = res.body.data
                })
                .catch(err => {
                    this.coffeeHouseItems = []
                })
                .finally(end => {
                    this.loadingCoffeeHouses = false
                })

        },
        async getRestaurants() {

            this.loadingRestaurants = true
            let params = {}
            params.filter = 'all';

            await this.$http
                .get("admin/restaurant", {
                    params: params,
                })
                .then(res => {
                    this.restaurantItems = res.body.data
                })
                .catch(err => {
                    this.restaurantItems = []
                })
                .finally(end => {
                    this.loadingRestaurants = false
                })

        },
        async getFitness() {
            this.loadingFitness = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/fitness`, {
                    params: params,
                })
                .then(res => {
                    this.fitnessItems = res.body.data
                })
                .catch(err => {
                    this.fitnessItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_fitness'))
                })
                .finally(end => {
                    this.loadingFitness = false
                });
        },
        async getHotels() {
            this.loadingHotel = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/hotel`, {
                    params: params,
                })
                .then(res => {
                    this.hotelItems = res.body.data
                })
                .catch(err => {
                    this.hotelItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_hotel'))
                })
                .finally(end => {
                    this.loadingHotel = false
                });
        },
        async getCinema() {
            this.loadingCinema = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/cinema`, {
                    params: params,
                })
                .then(res => {
                    this.cinemaItems = res.body.data
                })
                .catch(err => {
                    this.cinemaItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_cinema'))
                })
                .finally(end => {
                    this.loadingCinema = false
                });
        },
        async getPark() {
            this.loadingPark = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/park`, {
                    params: params,
                })
                .then(res => {
                    this.parkItems = res.body.data
                })
                .catch(err => {
                    this.parkItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_parks'))
                })
                .finally(end => {
                    this.loadingPark = false
                });
        },

        clearCertificate() {
            if (!this.certificate) {
                this.certificateItems = []
            }
        },
        redirectToCertificate(val) {
            if (val) {
                this.$router.push({
                    name: 'certificate.edit',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getCertificates(str) {
            if (str) {
                this.loadingCertificates = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.title = str
                }
                await this.$http
                    .get("admin/certificate", {
                        params: params,
                    })
                    .then(res => {
                        this.certificateItems = res.body.data
                    })
                    .catch(err => {
                        this.certificateItems = []
                    })
                    .finally(end => {
                        this.loadingCertificates = false
                    })
            }
        },

        clearNews() {
            if (!this.news) {
                this.newsItems = []
            }
        },
        redirectToNews(val) {
            if (val) {
                this.$router.push({
                    name: 'news.edit',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getNews(str) {
            if (str) {
                this.loadingNews = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.title = str
                }
                await this.$http
                    .get("admin/news", {
                        params: params,
                    })
                    .then(res => {
                        this.newsItems = res.body.data
                    })
                    .catch(err => {
                        this.newsItems = []
                    })
                    .finally(end => {
                        this.loadingNews = false
                    })
            }
        },




        async getPush() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/push/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                   // this.id = res.body.data.id
                    this.name = res.body.data.name
                    this.body = res.body.data.body
                    this.sex = res.body.data.sex_id
                    this.ageFrom = res.body.data.age_from
                    this.ageTo = res.body.data.age_to
                    this.type = res.body.data.type
                    if(this.type === 'my_link'){
                        this.link = res.body.data.link
                    }
                    console.log(res.body.data.date_from);
                    this.dateTime = this.$moment(res.body.data.date_from).format('YYYY-MM-DD HH:mm')
                    console.log(this.dateTime);
                    if(res.body.data.shop && res.body.data.shop.id){
                        this.shop = res.body.data.shop.id
                        this.shopItems = [res.body.data.shop]
                    }
                    if(res.body.data.certificate && res.body.data.certificate.id){
                        this.certificate = res.body.data.certificate.id
                        this.certificateItems = [res.body.data.certificate]
                    }
                    if(res.body.data.news && res.body.data.news.id){
                        this.news = res.body.data.news.id
                        this.newsItems = [res.body.data.news]
                    }
                    if(res.body.data.coffee_house && res.body.data.coffee_house.id){
                        this.coffee_house = res.body.data.coffee_house.id
                    }
                    if(res.body.data.restaurant && res.body.data.restaurant.id){
                        this.restaurant = res.body.data.restaurant.id
                    }
                    if(res.body.data.fitness && res.body.data.fitness.id){
                        this.fitness = res.body.data.fitness.id
                    }
                    if(res.body.data.hotel && res.body.data.hotel.id){
                        this.hotel = res.body.data.hotel.id
                    }
                    if(res.body.data.cinema && res.body.data.cinema.id){
                        this.cinema = res.body.data.cinema.id
                    }
                    if(res.body.data.park && res.body.data.park.id){
                        this.park = res.body.data.park.id
                    }
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_push'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async savePush() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.name) {
                formData.append('name', this.name)
            }
            if (this.body) {
                formData.append('body', this.body)
            }
            if (this.sex) {
                formData.append('sex', this.sex)
            }
            if (this.ageFrom) {
                formData.append('age_from', this.ageFrom)
            }
            if (this.ageTo) {
                formData.append('age_to', this.ageTo)
            }
            if (this.dateTime) {
                formData.append('datetime_send', this.dateTime)
            }
            if (this.link) {
                formData.append('link', this.link)
            }
            if (this.type) {
                if (this.type.id) {
                    formData.append('type', this.type.id)
                } else {
                    formData.append('type', this.type)
                }
            }
            if (this.certificate) {
                if (this.certificate.id) {
                    formData.append('certificate', this.certificate.id)
                } else {
                    formData.append('certificate', this.certificate)
                }
            }
            if (this.news) {
                if (this.news.id) {
                    formData.append('news', this.news.id)
                } else {
                    formData.append('news', this.news)
                }
            }
            if (this.shop) {
                if (this.shop.id) {
                    formData.append('shop', this.shop.id)
                } else {
                    formData.append('shop', this.shop)
                }
            }
            if (this.coffee_house) {
                if (this.coffee_house.id) {
                    formData.append('coffee_house', this.coffee_house.id)
                } else {
                    formData.append('coffee_house', this.coffee_house)
                }
            }
            if (this.restaurant) {
                if (this.restaurant.id) {
                    formData.append('restaurant', this.restaurant.id)
                } else {
                    formData.append('restaurant', this.restaurant)
                }
            }
            if (this.fitness) {
                if (this.fitness.id) {
                    formData.append('fitness', this.fitness.id)
                } else {
                    formData.append('fitness', this.fitness)
                }
            }
            if (this.hotel) {
                if (this.hotel.id) {
                    formData.append('hotel', this.hotel.id)
                } else {
                    formData.append('hotel', this.hotel)
                }
            }
            if (this.cinema) {
                if (this.cinema.id) {
                    formData.append('cinema', this.cinema.id)
                } else {
                    formData.append('cinema', this.cinema)
                }
            }
            if (this.park) {
                if (this.park.id) {
                    formData.append('park', this.park.id)
                } else {
                    formData.append('park', this.park)
                }
            }
            if (this.type_certificate) {
                if (this.type_certificate.id) {
                    formData.append('type_certificate', this.type_certificate.id)
                } else {
                    formData.append('type_certificate', this.type_certificate)
                }
            }
            if (this.$route.params.id) {
                await this.$http
                    .put(`admin/push/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('push_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('push_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/push', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('push_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            this.$router.push({
                                name: 'push.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'push'
                            })
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('push_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
