<template>
    <v-container fluid>
        <v-form>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'dashboard'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">
                                            {{ heading }}
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on"
                                                           @click="showQr()" icon>
                                                        <v-icon>mdi-qrcode</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{$t('print_qr_code')}}</span>
                                            </v-tooltip>
                                        </v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <make-logo v-model="logo" :aspect-ratio="(1/1)"/>
                            </v-col>
                            <v-col cols="12" sm="9" md="10">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="8">
                                        <ValidationProvider ref="cinema" rules="required|min:3|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="cinema" type="text" :error="!valid"
                                                          :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-coffee-maker"
                                                          :label="$t('cinema_name')" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="4">
                                        <ValidationProvider ref="phone" rules="required|phone"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="phoneRaw" type="tel"
                                                          :error="!valid"
                                                          v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                          :error-messages="errors" :disabled="loading"
                                                          :label="$t('phone')" color="primary"
                                                          prepend-icon="mdi-phone" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>


                                    <v-col class="py-0" cols="12" sm="8">
                                        <ValidationProvider ref="address" rules="min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="address" type="text" :error="!valid"
                                                          :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-home-map-marker"
                                                          :label="$t('address')" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="4">
                                        <ValidationProvider ref="responsible" rules="required|min:3|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="responsible" type="text" :error="!valid"
                                                          :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-account-settings"
                                                          :label="$t('cinema_responsible_name')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="8">
                                        <ValidationProvider ref="title" rules="min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="title" type="text" :error="!valid"
                                                          :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-format-title"
                                                          :label="$t('title_cinema')" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="4">
                                        <ValidationProvider ref="operating_mode" rules="required|min:3|max:20"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="operating_mode" type="text" :error="!valid"
                                                          :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-clock-time-seven-outline"
                                                          :label="$t('operating_mode')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>


                                </v-row>
                            </v-col>


                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="slogan" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="slogan" type="text" :error="!valid"
                                                  :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-format-text"
                                                  :label="$t('slogan_cinema')" color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="floor" name="floor"
                                                    rules="required|max_value:1|max_value:5"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="floor" type="text" :error="!valid"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-layers"
                                                  :label="$t('floor')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="office" name="office"
                                                    rules="required|min:1|max:100"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="office" type="text" :error="!valid"
                                                  :error-messages="errors" readonly :disabled="loading"
                                                  prepend-icon="mdi-layers"
                                                  @click="openHtmlPage"
                                                  :label="$t('office')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col v-if="false" class="py-0" cols="12" sm="3" >
                                <ValidationProvider ref="commission" name="commission"
                                                    rules="required|min_value:0|max_value:100"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="commission" type="text" :error="!valid"
                                                  :error-messages="errors" :disabled="loading"
                                                  append-icon="mdi-percent-outline mdi-18px"
                                                  prepend-icon="mdi-sack-percent"
                                                  :label="$t('commission_market')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col v-if="false" class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="min_sum" name="min_sum"
                                                    rules="required|min_value:0|max_value:999999"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="min_sum" type="text" :error="!valid"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-sack"
                                                  :label="$t('min_sum')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col v-if="false" class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="after_to_days" name="after_to_days"
                                                    rules="required|min_value:0|max_value:127"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="after_to_days" type="text" :error="!valid"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-calendar-weekend"
                                                  :label="$t('after_to_days')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col v-if="false" class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="discount" name="discount"
                                                    rules="required|min_value:0|max_value:100"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="discount" type="text" :error="!valid"
                                                  :error-messages="errors" :disabled="loading"
                                                  append-icon="mdi-percent-outline mdi-18px"
                                                  prepend-icon="mdi-sack-percent"
                                                  :label="$t('discount')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="two_gis" rules="min:1|max:500"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="two_gis" type="text" :error="!valid"
                                                  :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-map-marker-radius-outline"
                                                  :label="$t('link_two_gis')" color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="company" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="company" :items="companyItems"
                                                    :error-messages="errors" :error="!valid"
                                                    :search-input.sync="companySearching"
                                                    item-text="name" item-value="id"
                                                    prepend-icon="mdi-briefcase-account"
                                                    append-icon="mdi-location-enter"
                                                    :loading="loadingCompanies" :disabled="loading"
                                                    :no-data-text="companySearching ? $t('nothing_found_by',{'search': companySearching}) : $t('nothing_found_name')"
                                                    :label="$t('company')"
                                                    @click:clear="companyItems=[]"
                                                    @click="clearCompanies"
                                                    @click:append="redirectToCompany(company)"
                                                    color="primary" clearable autocomplete="off">
                                        <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.address_legal"></span>
                                            </span>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.address_legal"></span>
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <v-card class="mx-auto" outlined >
                                    <v-card-text>
                                        <div class="text-overline mb-4">
                                            {{$t('feedback_contacts')}}
                                        </div>
                                        <v-row>
                                            <v-col class="py-0" cols="12" sm="6">
                                                <ValidationProvider ref="feedback_phone" rules="phone"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="feedback_phoneRaw" type="tel"
                                                                  :error="!valid"
                                                                  v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                                  :error-messages="errors" :disabled="loading"
                                                                  :label="$t('phone')" color="primary"
                                                                  prepend-icon="mdi-phone" clearable>
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col class="py-0" cols="12" sm="6">
                                                <ValidationProvider ref="feedback_whatsapp" rules="phone"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="feedback_whatsappRaw" type="tel"
                                                                  :error="!valid"
                                                                  v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                                  :error-messages="errors" :disabled="loading"
                                                                  :label="$t('whatsapp')" color="primary"
                                                                  prepend-icon="mdi-whatsapp" clearable>
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-subheader class="mb-2 font-weight-medium">
                                    {{ $t('cinema_description') }}
                                </v-subheader>
                                <ValidationProvider ref="description"
                                                    rules="required|min:3|max:65535"
                                                    v-slot="{ errors, valid }">
                                    <tinymce-editor id="description" v-model="description"
                                                    :init="tinymceInit"></tinymce-editor>
                                    <div v-show="!valid" class="mt-2" style="color: red">
                                        {{ errors[0] ? errors[0] : $t('description_is_not_filled_out') }}
                                    </div>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="pt-0" cols="12">
                                <v-expansion-panels :disabled="loading">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="primary white--text">
                                            {{ $t('photo') }}
                                            <template v-slot:actions>
                                                <v-icon color="white">$expand</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <media-gallery v-model="images"/>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-8">
                        <v-progress-linear v-model="progress" :active="loading"
                                           class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn @click="saveCinema()" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
            <map-mall-dialog ref="mapMallDialog" @sendMapDialogParams="handleDialogParams"></map-mall-dialog>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import {mapGetters} from "vuex"
import debounce from "lodash/debounce"
import MakeLogo from '../components/MakeLogo.vue'
import MediaGallery from '../components/MediaGallery.vue'
import TinymceEditor from '@tinymce/tinymce-vue'
import MapMallDialog from '../components/MapMallDialog.vue'

export default {
    name: 'SettingCoffeeShop',
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        MakeLogo,
        MediaGallery,
        TinymceEditor,
        MapMallDialog
    },
    inject: ['forceRerender'],
    data() {

        return {

            tabActive: 0,
            heading: null,
            progress: 0,
            loading: false,
            loadingCompanies: false,
            tab: 0,
            language: null,
            errors: {},
            phoneRaw: null,
            id: null,
            logo: null,
            description: null,
            address: null,
            commission: 0,

            menu_pdf: null,
            min_sum: 0,
            after_to_days: 14,
            discount: 0,
            floor: null,
            office: null,
            operating_mode: null,
            slogan: null,
            title: null,
            cinema_uuid: 0,
            cinema_id: 0,
            cinema: null,
            responsible: null,
            images: [],
            company: null,
            companyItems: [],
            companySearching: null,
            exist_translations: {},
            all_translations: true,
            two_gis: null,
            passwordHidden: true,
            feedback_phoneRaw: null,
            feedback_whatsappRaw: null,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang','phoneMask']),
        languages() {
            return this.listLanguages
        },
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        feedback_phone: function () {
            return this.changePhone(this.feedback_phoneRaw)
        },
        feedback_whatsapp: function () {
            return this.changePhone(this.feedback_whatsappRaw)
        },
        tinymceInit() {
            return this.tinymceInitDefault()
        }
    },
    watch: {
        companySearching: debounce(function (val) {
            if (val && !this.company) {
                this.getCompanies(val)
            }
        }, 500),
    },

    async mounted() {
        this.language = this.languages[this.tab]
        this.loading = true
        this.checkCreate()
    },
    methods: {
        checkCreate() {
                this.heading = this.$t('cinema_editing')
                this.getCinema()

        },
        openHtmlPage() {
            this.$refs.mapMallDialog.openDialogWithLink(this.lang, this.floor);
        },
        handleDialogParams(map_id) {
            this.office = map_id;
        },
        clearCompanies() {
            if (!this.company) {
                this.companyItems = []
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        redirectToCompany(val) {
            if (val) {
                this.$router.push({
                    name: 'company.edit',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getCompanies(str) {
            if (str) {
                this.loadingCompanies = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.company = str
                }
                await this.$http
                    .get("admin/company", {
                        params: params,
                    })
                    .then(res => {
                        this.companyItems = res.body.data
                    })
                    .catch(err => {
                        this.companyItems = []
                    })
                    .finally(end => {
                        this.loadingCompanies = false
                    })
            }
        },
        async getCinema() {
            this.loading = true
            this.showMap = false
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/cinema/1`, {params: params})
                .then(async (res) => {
                    this.cinema_id = res.body.data.id
                    this.cinema_uuid = res.body.data.uuid
                    this.operating_mode = res.body.data.operating_mode
                    this.slogan = res.body.data.slogan
                    this.title = res.body.data.title
                    this.cinema = res.body.data.name
                    this.phoneRaw = res.body.data.phone
                    this.feedback_phoneRaw = res.body.data.feedback_phone
                    this.feedback_whatsappRaw = res.body.data.feedback_whatsapp
                    this.address = res.body.data.address
                    this.commission = res.body.data.commission
                    this.responsible = res.body.data.responsible
                    this.logo = res.body.data.logo
                    this.images = res.body.data.images
                    this.floor = res.body.data.floor
                    this.office = res.body.data.office
                    this.description = res.body.data.description
                    this.min_sum = res.body.data.min_sum
                    this.after_to_days = res.body.data.after_to_days
                    this.discount = res.body.data.discount
                    this.category_cinema = res.body.data.category_cinema_id

                    this.two_gis = res.body.data.two_gis
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations


                    let company = res.body.data.company
                    if (company && company.id) {
                        this.company = company.id
                        this.companyItems = [res.body.data.company]
                    } else {
                        this.companyItems = []
                    }

                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_cinema'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async saveCinema() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.cinema) {
                formData.append('cinema', this.cinema)
            }
            if (this.phone) {
                formData.append('phone', this.phone)
            }
            if (this.feedback_phone) {
                formData.append('feedback_phone', this.feedback_phone)
            }
            if (this.feedback_whatsapp) {
                formData.append('feedback_whatsapp', this.feedback_whatsapp)
            }
            if (this.address) {
                formData.append('address', this.address)
            }
            if (this.responsible) {
                formData.append('responsible', this.responsible)
            }
            if (this.description) {
                formData.append('description', this.description)
            }
            if (this.commission) {
                formData.append('commission', this.commission)
            }
            if (this.two_gis) {
                formData.append('two_gis', this.two_gis)
            }
            if (this.floor) {
                formData.append('floor', this.floor)
            }
            if (this.office) {
                formData.append('office', this.office)
            }

            if (this.min_sum >= 0) {
                formData.append('min_sum', this.min_sum)
            }
            if (this.after_to_days >= 0) {
                formData.append('after_to_days', this.after_to_days)
            }
            if (this.discount >= 0) {
                formData.append('discount', this.discount)
            }
            if (this.operating_mode) {
                formData.append('operating_mode', this.operating_mode)
            }
            if (this.slogan) {
                formData.append('slogan', this.slogan)
            }
            if (this.title) {
                formData.append('title', this.title)
            }

            if (this.category_cinema) {
                if (this.category_cinema.id) {
                    formData.append('category_cinema', this.category_cinema.id)
                } else {
                    formData.append('category_cinema', this.category_cinema)
                }
            }

            if (this.company) {
                if (this.company.id) {
                    formData.append('company', this.company.id)
                } else {
                    formData.append('company', this.company)
                }
            }


            if (this.logo) {
                if (this.logo.length > 250) {
                    var mimeTypeLogo = this.getMimeType(this.logo)
                    var blobLogo = this.dataURL64toBlob(this.logo)
                    if (mimeTypeLogo && blobLogo) {
                        formData.append('logo', blobLogo, mimeTypeLogo)
                    }
                } else {
                    formData.append('logo', this.logo)
                }
            }
            if (this.images && this.images.length > 0) {
                for (let i in this.images) {
                    if (this.images[i].length > 250) {
                        var mimeTypeImages = await this.getMimeType(this.images[i])
                        var blobImages = await this.dataURL64toBlob(this.images[i])
                        if (mimeTypeImages && blobImages) {
                            formData.append(`images[${i}]`, blobImages, mimeTypeImages)
                        }
                    } else {
                        formData.append(`images[${i}]`, this.images[i])
                    }
                }
            }

            if (this.menu_pdf) {
                formData.append('menu_pdf', this.menu_pdf)
            }


            // Save
            await this.$http
                .put(`admin/cinema/${this.cinema_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('cinema_has_been_updated'))
                    if (res && res.body && res.body.data && res.body.data.exist_translations) {
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('cinema_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async showQr() {
            this.loading = true
            let params = {};
            params.filter = 'search'

            params.type = 'cinema';
            params.id = this.cinema_id;

            await this.$http
                .get("admin/gr_create", {
                    params: params,
                })
                .then(res => {
                    const url = res.body.data.url;
                    if(url !== null){
                        this.download(url, res.body.data.name)
                    }
                    else{
                        this.$toastr.error(this.$t('file_download_error'))
                    }
                })
                .catch(err => {
                    //   this.companyItems = []
                    this.$toastr.error(this.$t('file_download_error'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        download(url, file_name) {
            fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = file_name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },
    }
}
</script>
