<template>
    <v-container fluid>
        <v-form @submit.prevent="getReserveRooms">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t('reserve_room') }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="room"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="room" :items="roomItems"
                                              :error-messages="errors" :disabled="loadingRoom"
                                              item-text="title"
                                              item-value="id" return-object
                                              prepend-icon="mdi-google-classroom" :label="$t('room')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="phone" rules="phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-phone" :label="$t('phone')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <v-dialog ref="reserveDate" v-model="reserveDate" width="290px">
                                    <v-date-picker ref="pickerTourFrom" v-model="from_date"
                                                   :locale="lang" @change="reserveDate = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="from_date" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="from_date" :error-messages="errors"
                                                  :disabled="loading" :label="$t('date_from')"
                                                  prepend-icon="mdi-calendar" color="primary" readonly
                                                  @click.stop="reserveDate = !reserveDate"
                                                  clearable @click:clear="from_date = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>


        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="reserve_roomItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalReserveRooms" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer
                              disable-pagination
                              single-expand :expanded.sync="expanded" item-key="uuid" show-expand
                >
                    <template v-slot:item.from_date="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{  item.from_date ? item.from_date : ''}}
                            {{ item.deleted ? ' (' + $t('cancelled') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.reservation_confirmed="{ item }">
                        {{ item.reservation_confirmed ? $t('yes') : $t('no')}}
                    </template>


                    <template v-slot:item.action="{ item }" class="text-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="showDetailsReserveRoom(item)" :title="$t('details')">
                                    mdi-card-text-outline
                                </v-icon>
                            </template>
                            <span>{{$t('details')}}</span>
                        </v-tooltip>

                        <v-tooltip bottom >
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="editReserveRoom(item)" :title="$t('edit')"

                                >
                                    mdi-square-edit-outline

                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>





                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="CancelReserveRoom(item)" :title="$t('cancel')"
                                 >
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{ $t('cancel') }}</span>
                        </v-tooltip>


                    </template>


                    <template v-slot:expanded-item="{ headers, item}">
                        <td :colspan="headers.length">
                            <v-row class="py-2 my-2">
                                <v-col cols="12" sm="12">
                                    <v-hover
                                        v-slot="{ hover }"
                                        open-delay="200"
                                    >
                                        <v-card
                                            :elevation="hover ? 16 : 2"
                                            :class="{ 'on-hover': hover }"
                                        >
                                            <v-card-text>
                                                <p class="py-0 my-0" v-if="!!item.room_type"><b>{{ $t('room_type') }}:</b> {{ item.room_type }}</p>
                                                <p class="py-0 my-0" v-if="!!item.room"><b>{{ $t('room') }}:</b> {{ item.room }}</p>
                                                <p class="py-0 my-0" v-if="!!item.name"><b>{{ $t('client_name') }}:</b> {{ item.name }}</p>
                                                <p class="py-0 my-0" v-if="!!item.phone"><b>{{ $t('phone') }}:</b> {{ item.phone }}</p>
                                                <p class="py-0 my-0" v-if="!!item.email"><b>{{ $t('email') }}:</b> {{ item.email }}</p>
                                                <p class="py-0 my-0" v-if="!!item.passport"><b>{{ $t('passport') }}:</b> {{ item.passport }}</p>
                                                <p class="py-0 my-0" v-if="!!item.for_yourself"><b>{{ $t('for_yourself') }}:</b>  {{ item.for_yourself ? $t('yes') : $t('no')}}</p>
                                                <p class="py-0 my-0"><b>{{ $t('reservation_confirmed') }}:</b>  {{ item.reservation_confirmed ? $t('yes') : $t('no')}}</p>
                                                <p class="py-0 my-0" v-if="!!item.amount"><b>{{ $t('amount') }}:</b>  {{  item.amount }}</p>
                                                <p class="py-0 my-0" v-if="!!item.date"><b>{{ $t('date') }}:</b>  {{  item.date }}</p>
                                                <p class="py-0 my-0" v-if="!!item.comment"><b>{{ $t('comment') }}:</b>  {{  item.comment }}</p>


                                                </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>


                        </td>
                    </template>

                </v-data-table>
                <v-card-actions class="justify-center">
                    <v-pagination v-show="totalReserveRooms > perPage" v-model="page" :total-visible="totalVisiblePag"
                                  :length="pageCount" :disabled="loading"></v-pagination>
                </v-card-actions>
            </v-card-text>

        </v-card>

        <v-dialog v-if="dialogDetails" v-model="dialogDetails" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="cancelDetails()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" :class="this.$vuetify.breakpoint.name !== 'xs' ? 'text-right' : 'pb-0'">
                            <v-col cols="12" class="py-1"><b>{{ $t('client_name')}}: </b>{{ detailsItems.name ? detailsItems.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('phone')}}: </b>{{ detailsItems.phone ? detailsItems.phone :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('email')}}: </b>{{ detailsItems.email ? detailsItems.phone :  $t('email')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('passport')}}: </b>{{ detailsItems.passport ? detailsItems.passport :  $t('passport')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('for_yourself')}}: </b>{{ detailsItems.for_yourself ?  $t('yes') : $t('no')  }}</v-col>

                        </v-col>
                        <v-col cols="12" sm="6" :class="this.$vuetify.breakpoint.name === 'xs' ? 'py-0' : ''">
                            <v-col cols="12" class="py-1"><b> {{ $t('room_type')}}:</b> {{ detailsItems.room_type ? detailsItems.room_type :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{ $t('room')}}:</b> {{ detailsItems.room ? detailsItems.room :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('date')}}: </b> {{ detailsItems.date ?  detailsItems.date :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('days_stay')}}: </b>{{ detailsItems.days_stay ? detailsItems.days_stay :  $t('no_data')  }}</v-col>

                            <v-col cols="12" class="py-1"><b>{{ $t('reservation_confirmed')}}: </b>{{ detailsItems.reservation_confirmed ?  $t('yes') : $t('no')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('amount')}}: </b> {{ detailsItems.amount ? detailsItems.amount :  $t('no_data')  }}</v-col>
                        </v-col>
                        <v-col cols="12" class="text-center" v-if="detailsItems.comment ? true : false">
                            <b>{{ $t('comment')}}: </b> {{ detailsItems.comment ? detailsItems.comment :  $t('no_data')  }}
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>

                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-if="reserve_roomData" v-model="dialogReserveRoom" max-width="800" persistent eager>
            <v-card>
                <ValidationObserver ref="observerForm" v-slot="{ invalid, validated, passes, validate }">
                    <v-card-title>
                        <span class="headline">
                            {{ $t('reserve_room') }}
                        </span>
                    </v-card-title>
                    <v-card-text class="pt-5">
                        <v-row>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="first_name"
                                                    rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="first_name" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-account"
                                                  :label="$t('first_name')" color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="last_name" name="last_name"
                                                    rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="last_name" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-account"
                                                  :label="$t('last_name')" color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="phone" rules="phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-phone" :label="$t('phone')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="email" name="email"
                                                    rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="email" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-email"
                                                  :label="$t('email')" color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="passport" name="passport"
                                                    rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="passport" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-passport"
                                                  :label="$t('passport')" color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="new_room" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="new_room" :items="roomItems"
                                              :error="!valid"
                                              :error-messages="errors" :disabled="loadingTypeTable"
                                              item-text="title" item-value="id" return-object
                                              prepend-icon="mdi-google-classroom" :label="$t('room')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="days_stay" name="days_stay"
                                                    rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="days_stay" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-calendar"
                                                  :label="$t('days_stay')" color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="8">
                                <v-dialog ref="new_reserveDate" v-model="new_reserveDate" width="290px">
                                    <v-date-picker  v-model="new_from_date"
                                                    :locale="lang" @change="new_reserveDate = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="new_from_date" rules="required|date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="new_from_date" :error-messages="errors"
                                                  :disabled="loading" :label="$t('check_in_date')"
                                                  prepend-icon="mdi-calendar" color="primary" readonly
                                                  @click.stop="new_reserveDate = !new_reserveDate"
                                                  clearable @click:clear="new_from_date = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="for_yourself"
                                          :disabled="loading"
                                          :label="$t('for_yourself')"
                                          color="primary" hide-details></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="reservation_confirmed"
                                          :disabled="loading"
                                          :label="$t('reservation_confirmed')"
                                          color="primary" hide-details></v-switch>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5">
                            <v-col cols="12">
                                <ValidationProvider ref="notes" rules="min:3|max:256"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="comment" type="text" :error-messages="errors"
                                                :disabled="loading" :label="$t('comment')" rows="5"
                                                color="primary" auto-grow clearable outlined>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="gray darken-1" class="ml-1 ml-sm-2" text @click="closeDialogReserveRoom">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text :loading="loadingSaveReserveRoom"
                               :disabled="invalid || loadingSaveReserveRoom" class="mr-1 mr-sm-2"
                               @click="saveReserveRoom()">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-dialog>






    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import {mask} from 'vue-the-mask'

export default {
    name: "RestaurantReserveRooms",
    directives: {
        mask,
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            phoneRaw: null,
            loadingRoom: false,
            room: null,
            roomItems: [],
            loading: false,
            dialogDetails: false,
            detailsItems:[],
            options: {},
            reserve_roomItems: [],

            expanded: [],


            sortBy: "id",
            sortDir: false,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalReserveRooms: 0,
            from_date: null,
            reserveDate: false,
            dialogReserveRoom: false,
            reserve_roomData: {},
            type_table: null,
            itemTypeTable: [],
            loadingTypeTable: false,
            table_number: null,
            itemTableNumber: [],
            loadingTableNumber: false,
            loadingSaveReserveRoom: false,
            new_room: null,
            first_name: null,
            last_name: null,
            email: null,
            passport: null,
            for_yourself: null,
            reservation_confirmed: null,
            new_from_date: null,
            new_reserveDate: false,
            new_dialogReserveRoom: false,

            comment: null,
            reserve_room_id: null,
            days_stay: null,

            headers: [
                {
                    text: this.$t('check_in_date'),
                    align: "left",
                    sortable: false,
                    value: "from_date"
                },
                {
                    text: this.$t('room'),
                    align: "left",
                    sortable: false,
                    value: "room"
                },
                {
                    text: this.$t('client_name'),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
                {
                    text: this.$t('phone'),
                    align: "center",
                    sortable: false,
                    value: "phone"
                },
                {
                    text: this.$t('days_of_stay'),
                    align: "center",
                    sortable: false,
                    value: "days_stay"
                },
                {
                    text: this.$t('reservation_confirmed'),
                    align: "center",
                    sortable: false,
                    value: "reservation_confirmed"
                },
                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
                {
                    text: '',
                    value: 'data-table-expand',
                    width: 40,
                    class: "handle",
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
    },
    async mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        await this.getRooms()
    },
    watch: {
        options: {
            handler() {
                this.getReserveRooms()
            },
            deep: false
        },


    },
    methods: {
        async getRooms() {
            this.loadingRoom = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.active = 1
            await this.$http
                .get(`admin/room/`, {params: params})
                .then(res => {
                    this.roomItems = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_rooms'));
                    this.roomItems = []

                })
                .finally(end => {
                    this.loadingRoom = false
                });
        },
        async editReserveRoom(item) {
            this.reserve_room_id = item.id
            this.days_stay = item.days_stay
            this.new_room = item.room_id
            this.new_from_date = this.$moment(item.from_date, 'DD.MM.YYYY').format('YYYY-MM-DD');
            this.comment = item.comment
            this.first_name = item.first_name
            this.last_name = item.last_name
            this.email = item.email
            this.passport = item.passport
            this.phoneRaw = item.phone
            this.for_yourself = item.for_yourself
            this.reservation_confirmed = item.reservation_confirmed
            this.reserve_roomData = Object.assign({}, item)
            this.dialogReserveRoom = true
        },
        closeDialogReserveRoom() {
            this.dialogReserveRoom = false
            this.new_room = null
            this.first_name = null
            this.last_name = null
            this.email = null
            this.passport = null
            this.phoneRaw = null
            this.for_yourself = null
            this.reservation_confirmed = null
            this.new_from_date = null
            this.comment = null
            this.reserve_room_id = null
            this.days_stay = null
            this.$nextTick(() => {
                this.reserve_roomData = {}
            })
        },
        async saveReserveRoom() {
            var _this = this
            this.progress = 0
            this.loading = true
            this.loadingSaveReserveRoom = true
            var formData = new FormData()

            if (this.new_from_date) {
                formData.append('from_date', this.new_from_date)
            }
            if (this.comment) {
                formData.append('comment', this.comment)
            }
            if (this.phone) {
                formData.append('phone', this.phone)
            }
            if (this.first_name) {
                formData.append('first_name', this.first_name)
            }
            if (this.days_stay) {
                formData.append('days_stay', this.days_stay)
            }
            if (this.last_name) {
                formData.append('last_name', this.last_name)
            }
            if (this.email) {
                formData.append('email', this.email)
            }
            if (this.passport) {
                formData.append('passport', this.passport)
            }
            formData.append('for_yourself', this.for_yourself ? 1 : 0)
            formData.append('reservation_confirmed', this.reservation_confirmed ? 1 : 0)
            if (this.new_room) {
                if (this.new_room.id) {
                    formData.append('room_id', this.new_room.id)
                } else {
                    formData.append('room_id', this.new_room)
                }
            }

            if (this.reserve_room_id) {
                // Save

                await this.$http
                    .put(`admin/reserve_room/${this.reserve_room_id}`, formData, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.$toastr.success(this.$t('reserve_room_has_been_updated'))
                        this.closeDialogReserveRoom()
                        this.getReserveRooms();

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('reserve_room_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                        this.loadingSaveReserveRoom = false
                    })
            }
        },
        async showDetailsReserveRoom(item) {
            this.loading = true;
            this.detailsItems = item
            this.dialogDetails = true;
            this.loading = false;

        },
        async getReserveRooms() {
            var _this = this
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options
            let params = {}
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc'
            }
            if (page !== undefined) {
                params.page = page
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage
            }

            if (this.lang) {
                params.language = this.lang
            }
            if (this.phone) {
                params.phone = this.phone
            }

            if (this.from_date) {
                params.from_date = this.from_date
            }

            if (this.room) {
                if (this.room.id) {
                    params.room_id =  this.room.id
                } else {
                    params.room_id =  this.room
                }
            }

            await this.$http
                .get("admin/reserve_room", {
                    params: params,
                })
                .then(res => {
                    this.reserve_roomItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalReserveRooms = res.body.meta.total
                    this.pageCount = res.body.meta.last_page

                })
                .catch(err => {
                    this.reserve_roomItems = []
                    this.page = 0
                    this.totalReserveRooms = 0
                    this.pageCount = 0
                    this.$toastr.error(this.$t('failed_to_get_list_reserve_rooms'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async CancelReserveRoom(item) {
            if (confirm(this.$t('delete_reserve_room'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/reserve_room/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('reserve_room_has_been_deleted'))
                        this.getReserveRooms()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('reserve_room_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        cancelDetails(){
            this.dialogDetails = false;
        },

    }
}
</script>
